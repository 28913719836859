/* Background image for the home page */
.home-background {
  background-image: url("../../../assets/background1.png"); /* Adjust the path as needed */
  height: 82vh; /* Or any desired height */
  background-size: cover;
  background-position: center;
  background-color: black;
  margin-left: 8vh;
  margin-right: 8vh;
}

.pogc {
  background-image: url("../../../assets/pogc.png");
}

.wwac {
  background-image: url("../../../assets/wwac.png");
}

.contact {
  background-image: url("../../../assets/contact.png");
}
